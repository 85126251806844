// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Place from "./pages/Place/Place.bs.js";
import * as React from "react";
import * as Homepage from "./pages/Homepage/Homepage.bs.js";
import * as MyPlaces from "./pages/MyPlaces/MyPlaces.bs.js";
import * as Core__List from "@rescript/core/src/Core__List.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ToastBanner from "./components/Toast/ToastBanner.bs.js";
import * as FcmTokenSync from "./components/FcmTokenSync/FcmTokenSync.bs.js";
import * as PageNotFound from "./pages/PageNotFound/PageNotFound.bs.js";
import * as PlaceSetting from "./pages/PlaceSetting/PlaceSetting.bs.js";
import * as SignInWrapper from "./components/SignInWrapper/SignInWrapper.bs.js";
import * as LoadingFullscreen from "./components/LoadingFullscreen/LoadingFullscreen.bs.js";
import * as ShareLinkResolver from "./pages/ShareLinkResolver/ShareLinkResolver.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as PlacePersonsSetting from "./pages/PlacePersonsSetting/PlacePersonsSetting.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as FirebaseAuthProvider from "./components/FirebaseAuthProvider/FirebaseAuthProvider.bs.js";

import './styles/index.css'
;

import '@oddbird/popover-polyfill'
;

function App(props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = url.path;
  var tmp;
  var exit = 0;
  if (match) {
    switch (match.hd) {
      case "misto" :
          exit = 1;
          break;
      case "s" :
          var match$1 = match.tl;
          tmp = match$1 && !match$1.tl ? JsxRuntime.jsx(FirebaseAuthProvider.make, {
                  children: JsxRuntime.jsx(SignInWrapper.make, {
                        children: JsxRuntime.jsx(ShareLinkResolver.make, {
                              linkId: match$1.hd
                            })
                      })
                }) : JsxRuntime.jsx(PageNotFound.make, {});
          break;
      default:
        tmp = JsxRuntime.jsx(PageNotFound.make, {});
    }
  } else {
    tmp = JsxRuntime.jsx(Homepage.make, {});
  }
  if (exit === 1) {
    var maybePlaceSubPath = Core__List.tail(url.path);
    var tmp$1;
    if (maybePlaceSubPath !== undefined && maybePlaceSubPath) {
      var placeId = Core__List.headExn(maybePlaceSubPath);
      var placeIdSub = Core__List.tail(maybePlaceSubPath);
      var tmp$2;
      if (placeIdSub !== undefined) {
        if (placeIdSub) {
          if (placeIdSub.hd === "nastaveni") {
            var match$2 = placeIdSub.tl;
            tmp$2 = match$2 ? (
                match$2.hd === "osob" && !match$2.tl ? JsxRuntime.jsx(PlacePersonsSetting.make, {
                        placeId: placeId
                      }) : JsxRuntime.jsx(PageNotFound.make, {})
              ) : JsxRuntime.jsx(PlaceSetting.make, {
                    placeId: placeId
                  });
          } else {
            tmp$2 = JsxRuntime.jsx(PageNotFound.make, {});
          }
        } else {
          tmp$2 = JsxRuntime.jsx(Place.make, {
                placeId: placeId
              });
        }
      } else {
        tmp$2 = JsxRuntime.jsx(PageNotFound.make, {});
      }
      tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(FcmTokenSync.make, {
                              placeId: placeId
                            })),
                    fallback: Caml_option.some(null)
                  }),
              tmp$2
            ]
          });
    } else {
      tmp$1 = JsxRuntime.jsx(MyPlaces.make, {});
    }
    tmp = JsxRuntime.jsx(FirebaseAuthProvider.make, {
          children: JsxRuntime.jsx(SignInWrapper.make, {
                children: tmp$1
              })
        });
  }
  return JsxRuntime.jsxs(React.Suspense, {
              children: [
                tmp,
                JsxRuntime.jsx(ToastBanner.make, {})
              ],
              fallback: Caml_option.some(JsxRuntime.jsx(LoadingFullscreen.make, {}))
            });
}

var make = App;

export {
  make ,
}
/*  Not a pure module */
