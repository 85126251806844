// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tilia from "@tilia/react/src/Tilia.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

var tree = Tilia.make({
      toasts: []
    }, undefined);

function addMessage(toast) {
  tree.toasts = Belt_Array.concatMany([
        tree.toasts,
        [toast]
      ]);
}

function removeMessage(id) {
  tree.toasts = tree.toasts.filter(function (message) {
        return message.id !== id;
      });
}

export {
  tree ,
  addMessage ,
  removeMessage ,
}
/* tree Not a pure module */
