// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ReactDOMStyle from "@rescript/react/src/ReactDOMStyle.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import ButtonMenuModuleCss from "./ButtonMenu.module.css";
import * as OddbirdCssAnchorPositioning from "../../3rdParty/OddbirdCssAnchorPositioning.bs.js";

var classes = ButtonMenuModuleCss;

function ButtonMenu(props) {
  var nodeId = React.useId().slice(1, -1);
  var popoverId = "popover-" + nodeId;
  var anchorId = "anchor-" + nodeId;
  var anchorName = "--button-menu-" + nodeId;
  React.useEffect((function () {
          OddbirdCssAnchorPositioning.polyfillDebounced();
        }), []);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                React.cloneElement(JsxRuntime.jsx("button", {
                          children: props.children,
                          className: props.className,
                          id: anchorId,
                          style: ReactDOMStyle.unsafeAddProp({}, "anchorName", anchorName),
                          title: props.title,
                          type: "button"
                        }), {
                      popovertarget: popoverId,
                      popovertargetaction: "toggle"
                    }),
                React.cloneElement(JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsx("nav", {
                                children: JsxRuntime.jsx("ul", {
                                      children: props.menuItems.map(function (item, idx) {
                                            return JsxRuntime.jsx("li", {
                                                        children: JsxRuntime.jsx("button", {
                                                              children: item.label,
                                                              disabled: Core__Option.getOr(item.disabled, false),
                                                              type: "button",
                                                              onClick: item.onClick
                                                            })
                                                      }, idx.toString());
                                          })
                                    })
                              }),
                          className: classes.popover,
                          id: popoverId,
                          style: ReactDOMStyle.unsafeAddProp({}, "positionAnchor", anchorName)
                        }), {
                      anchor: anchorId,
                      popover: ""
                    })
              ]
            });
}

var make = ButtonMenu;

export {
  classes ,
  make ,
}
/* classes Not a pure module */
