// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rxjs from "../Rxjs.bs.js";
import * as Rxjs$1 from "rxjs";
import * as Curry from "rescript/lib/es6/curry.js";
import Fn from "@oddbird/css-anchor-positioning/fn";

var debouncedPolyfillSource = new Rxjs$1.Subject(undefined);

var debouncedPolyfillSubscription = Rxjs.op(debouncedPolyfillSource, Rxjs$1.debounceTime(100)).subscribe(function () {
      Curry._1(Fn, undefined);
    });

function polyfillDebounced() {
  debouncedPolyfillSource.next();
}

export {
  debouncedPolyfillSource ,
  debouncedPolyfillSubscription ,
  polyfillDebounced ,
}
/* debouncedPolyfillSource Not a pure module */
