// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Db from "../../backend/Db.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as LoadingInline from "../../components/LoadingInline/LoadingInline.bs.js";
import * as FormattedVolume from "../../components/FormattedVolume/FormattedVolume.bs.js";
import * as FormattedPercent from "../../components/FormattedPercent/FormattedPercent.bs.js";
import * as FormattedCurrency from "../../components/FormattedCurrency/FormattedCurrency.bs.js";
import * as SectionWithHeader from "../../components/SectionWithHeader/SectionWithHeader.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import DepletedKegsModuleCss from "./DepletedKegs.module.css";

var classes = DepletedKegsModuleCss;

function DepletedKegs(props) {
  var maybeDepletedKegs = props.maybeDepletedKegs;
  var maybeFetchMoreDepletedKegs = props.maybeFetchMoreDepletedKegs;
  return JsxRuntime.jsx(SectionWithHeader.make, {
              children: maybeDepletedKegs !== undefined ? (
                  maybeDepletedKegs.length !== 0 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                          children: [
                            JsxRuntime.jsxs("table", {
                                  children: [
                                    JsxRuntime.jsx("thead", {
                                          children: JsxRuntime.jsxs("tr", {
                                                children: [
                                                  JsxRuntime.jsx("th", {
                                                        children: "No.",
                                                        scope: "col"
                                                      }),
                                                  JsxRuntime.jsx("th", {
                                                        children: "Pivo",
                                                        scope: "col"
                                                      }),
                                                  JsxRuntime.jsx("th", {
                                                        children: "Objem",
                                                        scope: "col"
                                                      }),
                                                  JsxRuntime.jsx("th", {
                                                        children: "Vypito",
                                                        scope: "col"
                                                      }),
                                                  JsxRuntime.jsx("th", {
                                                        children: "Ztráty",
                                                        scope: "col"
                                                      }),
                                                  JsxRuntime.jsx("th", {
                                                        children: "Naskladněno",
                                                        scope: "col"
                                                      }),
                                                  JsxRuntime.jsx("th", {
                                                        children: "Naraženo",
                                                        scope: "col"
                                                      }),
                                                  JsxRuntime.jsx("th", {
                                                        children: "Dopito",
                                                        scope: "col"
                                                      }),
                                                  JsxRuntime.jsx("th", {
                                                        children: "Cena sudu",
                                                        scope: "col"
                                                      }),
                                                  JsxRuntime.jsx("th", {
                                                        children: "Velké pivo",
                                                        scope: "col"
                                                      }),
                                                  JsxRuntime.jsx("th", {
                                                        children: "Efektivita",
                                                        scope: "col"
                                                      })
                                                ]
                                              })
                                        }),
                                    JsxRuntime.jsx("tbody", {
                                          children: maybeDepletedKegs.map(function (keg) {
                                                var volume = keg.milliliters;
                                                var kegId = Db.getUid(keg);
                                                var effectivity = keg.consumptionsSum / volume;
                                                var priceLargeBeer = keg.price / keg.milliliters * 500.0 | 0;
                                                return JsxRuntime.jsxs("tr", {
                                                            children: [
                                                              JsxRuntime.jsx("th", {
                                                                    children: keg.serialFormatted,
                                                                    scope: "row"
                                                                  }),
                                                              JsxRuntime.jsx("td", {
                                                                    children: keg.beer
                                                                  }),
                                                              JsxRuntime.jsx("td", {
                                                                    children: JsxRuntime.jsx(FormattedVolume.make, {
                                                                          milliliters: volume
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx("td", {
                                                                    children: JsxRuntime.jsx(FormattedVolume.make, {
                                                                          milliliters: keg.consumptionsSum
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx("td", {
                                                                    children: JsxRuntime.jsx(FormattedVolume.make, {
                                                                          milliliters: volume - keg.consumptionsSum | 0
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx("td", {
                                                                    children: JsxRuntime.jsx(ReactIntl.FormattedDate, {
                                                                          value: keg.createdAt.toDate()
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx("td", {
                                                                    children: JsxRuntime.jsx(ReactIntl.FormattedDate, {
                                                                          value: new Date(Core__Option.getExn(Db.kegFirstConsumptionTimestamp(keg), undefined))
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx("td", {
                                                                    children: JsxRuntime.jsx(ReactIntl.FormattedDate, {
                                                                          value: Core__Option.getExn(Caml_option.null_to_opt(keg.depletedAt), undefined).toDate()
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx("td", {
                                                                    children: JsxRuntime.jsx(FormattedCurrency.make, {
                                                                          value: keg.price
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx("td", {
                                                                    children: JsxRuntime.jsx(FormattedCurrency.make, {
                                                                          value: priceLargeBeer / effectivity | 0
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx("td", {
                                                                    children: JsxRuntime.jsx(FormattedPercent.make, {
                                                                          value: effectivity * 100.0
                                                                        })
                                                                  })
                                                            ]
                                                          }, kegId);
                                              })
                                        })
                                  ],
                                  className: classes.table
                                }),
                            maybeFetchMoreDepletedKegs !== undefined ? JsxRuntime.jsx("button", {
                                    children: "Načíst další",
                                    className: Styles.button.base + " " + classes.fetchMoreButton,
                                    type: "button",
                                    onClick: (function (param) {
                                        maybeFetchMoreDepletedKegs();
                                      })
                                  }) : null
                          ]
                        }) : JsxRuntime.jsx("p", {
                          children: "Zde bude přehled dopitých sudů, zatím jste žádný nedopili 🤷‍♂️",
                          className: SectionWithHeader.classes.emptyMessage
                        })
                ) : JsxRuntime.jsx("p", {
                      children: JsxRuntime.jsx(LoadingInline.make, {}),
                      className: SectionWithHeader.classes.emptyMessage
                    }),
              buttonsSlot: null,
              headerId: "depleted_kegs",
              headerSlot: "Dopité sudy"
            });
}

var make = DepletedKegs;

export {
  classes ,
  make ,
}
/* classes Not a pure module */
